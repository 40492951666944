.container {
  padding-top: 2rem;
}

.main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.info {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
}

.title {
  margin: 0.5rem 0 0 0;
  line-height: 1.15;
  font-size: 3rem;
}

.title,
.description {
  text-align: center;
}

.description {
  margin: 2rem 2rem;
  max-width: 80%;
  line-height: 1.5;
  font-size: 1.6rem;
}

.features {
  line-height: 1.5;
  font-size: 1.4rem;
}

.app-store {
  margin: 5rem;
}

.screenshots {
  flex: 1;
  padding: 0rem 2rem;
  text-align: center;
}

.screenshots img {
  margin: 1rem;
}

a {
  color: inherit;
  text-decoration: none;
}
ul {
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;

}
.footer {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0;
  border-top: 1px solid #eaeaea;
  justify-content: center;
  align-items: center;
}
.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin: 0.25rem 0;
}
.github {
  height: 1em;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

@media (max-width: 1439px) {
  .main {
    display: block;
  }
  .screenshots {
    overflow-x: scroll;
    white-space: nowrap;
  }
}

.privacy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em;
}